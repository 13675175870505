<template>
  <div class="p-10">
    <div class="flex mb-10">
      <div class="mr-3"><img alt="logo" src="../assets/logo.svg"></div>
      <div class="grid text-left">
        <div class="text-2xl">Say Hi,</div>
        <div class="text-2xl font-semibold">To your <span class="text-red-600">Health</span></div>
      </div>
    </div>
  </div>
  <div class="flex justify-between">
    <div><img alt="logo" src="../assets/hi-hub-login.svg"></div>
    <div class="shadow grid mr-32 px-10 py-16 text-left bg-white">
      <div class="text-2xl">Login</div>
      <div class="text-xs mb-10">Welcome to admin portal</div>

      <form @submit.prevent="loginSubmit">
        <label for="username" class="grid">
          Username:
          <input type="text" v-model="username" required
            class="border border-gray-300 text-gray-900 text-sm w-96 h-10 rounded px-2"
          >
        </label>
        <br>
        <label for="password" class="grid">
          Password:
          <input type="password" v-model="password" required
            class="border border-gray-300 text-gray-900 text-sm h-10 rounded px-2"
          >
        </label>
        <br>
        <button type="submit"
          class="bg-red-500 w-full text-white p-2 font-medium mt-10"
        >Login</button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data() {
    return {
      password: '',
      username: '',
    };
  },
  methods: {
    ...mapActions([
      'login',
    ]),
    loginSubmit() {
      const username = 'hi.hub@artlir.com';
      const password = 'Art@1234';

      if (this.username === username && this.password === password) {
        this.login({ username, password });
        localStorage.setItem('isLoggedIn', 'true');
        this.$router.push('/dashboard');
      }
      // login false;
    },
  },
});
</script>
