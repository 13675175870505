import { createStore } from 'vuex';

export default createStore({
  state: {
    isLoggedIn: false,
    user: null,
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    user: (state) => state.user,
  },
  mutations: {
    login(state, user) {
      state.isLoggedIn = true;
      state.user = user;
      console.log(state.isLoggedIn);
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
  actions: {
    login({ commit }, user) {
      // Perform login logic, e.g., send request to a server
      // If login is successful, commit the login mutation
      commit('login', user);
    },
    logout({ commit }) {
      // Perform logout logic, e.g., clear login details
      commit('logout');
    },
  },
  modules: {
  },
});
