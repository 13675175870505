import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';
import NotFoundPage from '../views/NotFoundPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:routePath(login)?',
    name: 'home',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DashBoard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/preview-artical/:id',
    name: 'preview',
    component: () => import(/* webpackChunkName: "about" */ '../views/PreviewArtical.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:pathMatch(.*)',
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function checkLogin() {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  // Implement your actual login check logic here
  // Return true if the user is logged in, false otherwise
  // You can store the login status in Vuex, local storage, or a global variable
  // and retrieve it here
  return isLoggedIn; // Replace with your login check logic
}

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    // Check if the user is logged in, replace this with your actual login check logic
    const isLoggedIn = checkLogin(); // Assuming you have a function to check login status
    if (isLoggedIn === 'false') {
      next('/'); // Redirect to login page if not logged in
    } else {
      next(); // Continue to the protected route if logged in
    }
  } else {
    next(); // Continue to other routes
  }
});

export default router;
